<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content">
      <ReceptionHeader>
        <PaymentActionFlow
          payment_status="done"
          select_status="active"
          post_status="active"
          complete_status="active"
        />
      </ReceptionHeader>
      <article class="message is-success is-small" v-if="success_message">
        <div class="message-body">
          {{ success_message }}
        </div>
      </article>
      <div>
        <div class="info">
          お支払い手続きが完了していません。<br />
          発送される場合は最初からやり直してください。
        </div>
        <div class="btn-wrapper">
          <button
            class="button is-rounded is-medium btn-submit"
            @click="toPaymentReception"
            v-if="is_allow_action"
          >
            やり直す
          </button>
        </div>
        <p>
          スマリ宅配ボックスを使用しない場合は、ローソンなどに設置しているスマリボックスなど、他の発送方法をご利用ください。<br />
          スマリボックス設置拠点については
          <a class="area-link is-underlined" href="https://smari.io/location" target="_blank">こちら</a>
        </p>
        <p>発送しない方はブラウザを閉じてください。</p>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay"
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import ReceptionHeader from "../components/ReceptionHeader.vue"

export default {
  name: "PaymentReceptionFailed",
  data() {
    return {
      is_loading: false,
      is_allow_action: true,
      error_message: "",
      success_message: "",
      reception: {
        sr_code: "",
        service_trc_no: "",
        optional_service_no: "",
      },
    }
  },
  components: {
    Loading,
    PaymentActionFlow,
    ReceptionHeader,
  },
  computed: {
    store() {
      return this.$store.state.store
    },
  },
  async beforeMount() {
    this.is_loading = true
    if (this.$route.query.token === undefined) {
      this.error_message = "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }

    try {
      let reception = await DeliveryboxReceptionRepository.findByToken(this.$route.query.token)
      this.reception.sr_code = reception.sr_code
      this.reception.service_trc_no = reception.service_trc_no
      this.reception.optional_service_no = reception.optional_service_no
    } catch (error) {
      this.error_message = "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      return
    } finally {
      this.is_loading = false
    }

    this.is_allow_action = true
  },
  methods: {
    async toPaymentReception() {
      let query = {
        sr: this.reception.sr_code,
        trc: this.reception.service_trc_no,
      }
      if (this.reception.optional_service_no) {
        query.opt = this.reception.optional_service_no
      }
      this.$router.push({
        path: "/payment-reception",
        query: query,
      })
    },
  },
}
</script>

<style scoped>
.info p {
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.btn-wrapper {
  text-align: center;
  padding: 20px 0 50px;
}
.area-link {
  color: #092c0b;
}
</style>
